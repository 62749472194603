import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 360.000000 360.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)">
          <path d="M1685 2640 c-207 -33 -426 -154 -545 -303 -49 -62 -140 -214 -140
-234 0 -10 22 -13 84 -13 l84 0 27 53 c74 142 199 262 348 336 161 79 331 101
504 65 194 -40 364 -153 478 -317 68 -96 66 -76 -2 27 -119 180 -303 312 -513
367 -88 23 -241 32 -325 19z"/>
<path d="M1752 2499 c-82 -14 -203 -65 -279 -118 -79 -56 -177 -158 -212 -222
-28 -51 -21 -45 39 34 110 148 318 247 515 247 214 0 425 -113 546 -292 l40
-58 64 0 c35 0 66 4 69 8 9 15 -58 116 -121 183 -169 178 -413 259 -661 218z"/>
<path d="M980 1800 l0 -230 70 0 70 0 0 85 0 85 118 0 117 0 43 55 c23 30 42
57 42 60 0 3 -72 5 -160 5 l-160 0 0 25 0 25 130 0 130 0 40 51 c22 28 40 55
40 60 0 5 -102 9 -240 9 l-240 0 0 -230z"/>
<path d="M1552 1974 l-43 -59 175 -5 c157 -4 177 -7 203 -26 61 -45 66 -113
12 -158 -37 -31 -72 -38 -174 -34 l-80 3 -1 80 -2 80 -68 0 -69 0 0 -140 0
-140 159 -3 c231 -4 318 23 377 116 75 118 29 254 -104 313 -44 19 -74 23
-197 27 l-145 4 -43 -58z"/>
<path d="M2134 2017 c-2 -7 -3 -109 -2 -227 l3 -215 196 -3 195 -2 47 60 47
60 -175 0 -175 0 0 170 0 170 -65 0 c-44 0 -67 -4 -71 -13z"/>
<path d="M1000 1497 c0 -22 93 -175 145 -239 102 -123 282 -235 450 -279 117
-31 300 -31 415 0 223 59 442 225 533 403 15 29 22 45 15 34 -92 -132 -158
-197 -273 -267 -138 -83 -263 -114 -434 -106 -136 6 -233 33 -351 98 -103 57
-241 197 -293 297 l-38 72 -85 0 c-62 0 -84 -3 -84 -13z"/>
<path d="M2361 1452 c-186 -274 -546 -369 -844 -221 -81 40 -169 111 -219 178
-58 78 -65 83 -37 31 34 -64 182 -208 259 -253 293 -169 669 -110 902 142 48
51 118 155 118 175 0 3 -31 6 -70 6 l-69 0 -40 -58z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
